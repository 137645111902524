import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import style from "./about.module.css"
import PageTitle from "../components/page-title"
import { Heading, SlashHeading } from "../components/heading"
import SEO from "../components/seo"

export default function About({ data }) {
  return (
    <Layout>
      <SEO
        title="About"
        description="About Decent Alternative Systems"
      />
      <PageTitle>About {data.site.siteMetadata.title}</PageTitle>        
            
      <div class={style.row}>
        <div class={style.column}>
          <Img sizes={data.file.childImageSharp.sizes} />
        </div>
        <div class={style.column_middle}> <br></br></div>
        <div class={style.column}>
          <Heading>Bio</Heading>
          <p class={style.para}>
            Hi! I'm Connor!
            <br />
            <br />
            Currently building the future of decentralized finance at Ava Labs. 
            Baltimore based, New Orleans born, Heidelberg raised. I write about 
            technology and society in my free time.
          </p>
        </div>
      </div>
      <Heading>Tech Career</Heading>
      <SlashHeading>now</SlashHeading>
      <p class={style.para}>
      I'm a software engineer at Ava Labs. I work on building and helping others 
      develop decentralized applications on the Avalanche network. 
      </p>
      <SlashHeading>before</SlashHeading>
      <p class={style.para}>
      I spent four years as a defensive cybersecurity researcher at the Johns Hopkins 
      Applied Physics Lab. I worked in trusted computing, secure hardware, cloud 
      security, attestation and measurement, blockchain, microkernels, and 
      cryptography. I also have a masters in Data Science and have worked in synthetic 
      data generation for image classification.
      </p>
      
    <div>
      <Heading>Interests</Heading>
      <SlashHeading>storytelling</SlashHeading>
      <p class={style.para}>
      Stories are the fundamental unit of human thought. I'm curious about how stories 
      shape human identities and how stories can be used to direct human society. 
      Archetypal stories make up far more diverse portion of the human canon than just 
      novels. Liberalism and Christianity are examples of such stories.
      </p>

      <SlashHeading>narrative</SlashHeading>
      <p class={style.para}>
      Social media is the foundation of modern discourse. Twenty-first century stories
       are written on Twitter. These stories don't always arise organically. 
       Entrepreneurs, trolls, and information warfare operatives attempt to bend the arc 
       of society to fit their narratives. Narratives are weaponized stories.
      </p>

      <SlashHeading>altsociety</SlashHeading>
      <p class={style.para}>
      So much of society runs on default. Too often we confuse the way things are for the 
      way things must be. I believe in interrogating our assumptions about how society 
      should be structured and conceptualizing alternate proposals for how we can live. 
      </p>

      <SlashHeading>crypto</SlashHeading>
      <p class={style.para}>
      The most fertile ground for reorganizing society today is the field of cryptocurrency 
      and decentralized systems. I've been involved in the blockchain space since 2014 and 
      believe it will see exponential growth in the next few years. The original generation 
      of cryptocurrencies won't be able to handle the increased demand, and new platforms 
      like Avalanche will take over. 
      </p>

      <SlashHeading>misc</SlashHeading>
      <p class={style.para}>
      I'm a huge sports fan. New Orleans Saints, New Orleans Pelicans, Baltimore Orioles. I 
      know a lot about football and baseball analytics and have performed a couple research
      projects.

      <br/> <br/>

      I've been a runner since 2010 and ran my first marathon in 2019. Global pandemic willing, 
      I hope to run my second in 2021. 

      <br/> <br/>

      My other big hobbies are cooking and photography. I love exploring the craft of cooking 
      and understanding what distinguishes the world's greatest chefs from everybody else. 
      Photography is about understanding perception and the pursuit of visual beauty.
      </p>
    </div>

    <div>
      <Heading>Harmony of Pen and Sword</Heading>
      <p class={style.para}>
        Paul Schrader's film <i>Mishima: A Life in Four Chapters</i> explores the life of Japanese 
        author Yukio Mishima (1925 - 1970). The surreal biopic illustrates Mishima's attempts 
        to reconcile the physical world with the world of words, thoughts with actions.  
        Mishima ultimately adopts an ancient samurai code known as the harmony of pen and sword, 
        living a life where one's deeds perfectly align with one's actions, a life free of 
        cognitive dissonance. While ultimately an unobtainable ideal, I'm a sucker for a man
        living by a code, so here I try to do the same. Pens and swords are relics of the past. 
        In 2020, we have the harmony of blog and code.
      </p>
    </div>
    
    <div>
      <Heading>Decent Alternative Systems</Heading>
      <p class={style.para}>
        The name is a bit of a pun. Decentralized systems are sometimes abbreviated as "decents." 
        Many of my ideas revolve around building decentralized alternatives to modern 
        infrastructure. Hence, Decent Alternative Systems.
      </p>
    </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "assets/connor-headshot-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`